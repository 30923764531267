import React, { useRef, useState }  from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Img from 'gatsby-image';
import Slider from 'react-slick';

import "slick-carousel/slick/slick.css"; 

import Layout from '../components/layout';
import SEO from '../components/seo';
import Header from '../components/header';

import { InstagramIcon, FacebookIcon, WomenPicture, MenPicture } from '../components/icons';

const query = graphql`
    query HomeData {
        pageContent: allFile(filter: {relativePath: {eq: "home.md"}}) {
            nodes {
              childMarkdownRemark {
                frontmatter {
                  background {
                    image {
                      childImageSharp {
                        fluid(maxWidth: 1440, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                      }
                    }
                    heading
                    subheading
                  }
                  what {
                    heading
                    text
                  }
                  gerihte {
                    heading
                    text
                    slides {
                      heading
                      image {
                        childImageSharp {
                          fixed(width:562, height:519) {
                            ...GatsbyImageSharpFixed
                          }
                        }
                      }
                      text
                    }
                  }
                }
              }
            }
        }
        social: allFile(filter: {relativePath: {eq: "info.md"}}) {
            nodes {
              childMarkdownRemark {
                frontmatter {
                  social {
                    facebook
                    instagram
                  }
                }
              }
            }
        }
    }
`;

// instagram: allInstaNode(limit: 4) {
//     edges {
//       node {
//         id
//         localFile {
//           childImageSharp {
//             fixed(width:252, height:252) {
//                 ...GatsbyImageSharpFixed
//             }
//           }
//         }
//       }
//     }
// }

const IndexPage = () => {

    const data = useStaticQuery(query);
    const pageContent = data.pageContent.nodes[0].childMarkdownRemark.frontmatter;
    const socialLinks = data.social.nodes[0].childMarkdownRemark.frontmatter.social;

    const slider = useRef(null);
    const [ activeSlide, setActiveSlide ] = useState(0);

    const sliderSettings = {
        dots: false,
        arrows: false,
        infinite: true,
        fade: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (current,next) => {
            setActiveSlide(next);
        }
    };

    const changeSlideHandler = index => {
        slider.current.slickGoTo(index);
    }

    return (
        <Layout>
            <SEO title='Home' description='Schnelles Essen trifft auf gesunde Küche' />
            <Header type='home' image={pageContent.background.image.childImageSharp.fluid} heading={pageContent.background.heading} subheading={pageContent.background.subheading} />
            <div className="home__what">
                <div className="container">
                    <div className="home__what--content">
                        <div className="home__what--text">
                            <div className="heading">
                                {pageContent.what.heading}
                            </div>
                            <p dangerouslySetInnerHTML={{ __html: pageContent.what.text }} />
                            <Link to='/uber-uns' className="btn btn-green">
                                mehr erfahren
                            </Link>
                        </div>
                        <div className="home__what--images">
                            <div className="top">
                                <div className="image">
                                    <MenPicture />
                                </div>
                                <div className="image">
                                    <WomenPicture />
                                </div>
                            </div>
                            <div className="bottom">
                                <div className="image">
                                    <WomenPicture />
                                </div>
                                <div className="image">
                                    <MenPicture />
                                </div>
                                <div className="image">
                                    <WomenPicture />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="home__dishes">
                <div className="container">
                    <h3 className="heading">
                        {pageContent.gerihte.heading}
                    </h3>
                    <p>
                        {pageContent.gerihte.text}
                    </p>
                </div>
                <div className="home__dishes--slider">
                    <Slider {...sliderSettings} ref={slider}>
                        {pageContent.gerihte.slides.map((slide, index) => {

                            return ( 
                                <div className="slide" key={index}>
                                    <div className="container">
                                        <div className="box">
                                            <div className="image">
                                                <div className="number">
                                                    {`0${index + 1}`}
                                                </div>
                                                <Img fixed={slide.image.childImageSharp.fixed} className="img" />
                                            </div>
                                            <div className="text">
                                                <h4 className="heading">
                                                    {slide.heading}
                                                </h4>
                                                <p>
                                                    {slide.text}
                                                </p>
                                                <Link to='/gerichte' className="btn btn-green">
                                                    Menükarte
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </Slider>
                </div>
                <div className="container">
                    <div className="cta">
                        <div className="circles-container">
                            {pageContent.gerihte.slides.map((slide,index) => {
                                return (
                                    <div key={index} className={`circle ${index === activeSlide ? 'active' : ''}`} onClick={() => changeSlideHandler(index)} />
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>

            <div className="home__social">
                <div className="container">
                    <div className="heading">
                        Folge uns!
                    </div>
                    <div className="icons">
                        <div className="icon">
                            <a href={socialLinks.instagram} target="__blank" rel="noreferrer noopener">
                                <InstagramIcon />
                            </a>
                        </div>
                        <div className="icon">
                            <a href={socialLinks.facebook} target="__blank" rel="noreferrer noopener">
                                <FacebookIcon />
                            </a>
                        </div>
                    </div>
                    {/* <div className="home__social--feed">
                        {data.instagram.edges.map((image, index) => (
                            <div className="image" key={index}>
                                <a href={`https://www.instagram.com/p/${image.node.id}`} target="_blank" >
                                    <Img fixed={image.node.localFile.childImageSharp.fixed} />
                                </a>
                            </div>
                        ))}
                    </div> */}
                </div>
            </div>
        </Layout>
    );
}

export default IndexPage;